<template>
    <StationComp />
</template>
  
  <script>
  // @ is an alias to /src
  import StationComp from '@/components/StationComp.vue'
  import firebase from 'firebase/compat/app';
  import 'firebase/auth';
  import 'firebase/firestore';
  import router from '../router'
  export default {
      name: 'SigninView',
      components: {
          StationComp
      },
      beforeCreate() {
        var c = this.$route.params.code.toUpperCase();
        this.code = c;
        firebase.database().ref('StationBox').child('station').child(c).on('value', (snapshot) => {
            this.stations = snapshot.val();
            if (!snapshot.hasChild('SUB01')) {
                router.replace("/")
            }
            console.log(this.stations);
        });
          firebase.auth().onAuthStateChanged((user) => {
              if (!user) {
                  router.replace("/signin")
              }
          });
      },
  
  }
  </script>
  
<template>
    <div class="container">
        <div class="row gx-5 gy-2"
            style="height: 54px; text-align: start; padding-top: 20px; font-size: 24px; margin-bottom: 30px;">
            <div class="col-md-12 col-lg-6" style="padding-left: 20px !important;">StationBox :: ผู้ใช้งานใกล้หมดอายุ
            </div>
        </div>
        <div class="row gx-5 gy-2"
            style="height: 54px; text-align: start; padding-top: 20px; font-size: 24px; margin-bottom: 30px;">
            <div class="col-md-12 col-lg-6 " v-for="(item, _index) in usersEXP" v-bind:key="_index"
                style="padding-left: 20px !important;">
                <div class="row station">
                    <div class="col col-exp">
                        {{ item.phone }} ({{item.station.e_date}}วัน) วันที่หมดอายุ {{ exp(item.station.p_date, item.station.e_date) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import "firebase/compat/database";
//import router from '../router'

export default {
    name: 'UserComp',
    data() {
        return {
            usersEXP: [],
        };
    },
    methods: {
        exp: function (_p_date, _e_date) {
            var _exp = new Date(_p_date)
            _exp.setDate(_exp.getDate() + _e_date)
            return this.toDigi(_exp.getDate()) + "/" + this.toDigi(_exp.getMonth() + 1) + "/" + this.toDigi(_exp.getFullYear() + 543)
        },
        toDigi: function (d) {
            if (d < 10) {
                return "0" + d
            } else {
                return d
            }
        }
    },
    mounted() {
        firebase.database().ref('StationBox').child('userdata').on('value', (snapshot) => {
            snapshot.forEach((u) => {
                firebase.database().ref('StationBox').child('userdata').child(u.key).child('station').on('value', (sna) => {
                    if (sna.hasChild('p_date')) {
                        var now = new Date().getTime()
                        var e_date = sna.child('e_date').val()
                        var p_date = sna.child('p_date').val()
                        var exp = new Date(p_date)
                        exp.setDate(exp.getDate + (e_date - 3))
                        var lexp = exp.getTime();
                        if (now >= lexp) {
                            firebase.database().ref('StationBox').child('userdata').child(u.key).on('value', (usnp) => {
                                this.usersEXP.push(usnp.val())
                            })
                        }

                    }
                })
            })
            //this.users = snapshot.val()
            console.log(this.usersEXP);
        });
    },
}
</script>
<style lang="scss">
/* Clear floats after the columns */
.station_box {
    height: 54px;
    background-color: aliceblue;
    text-align: center;
    border-radius: 8px;
    padding-top: 16px;
}

.station_box_use {
    height: 54px;
    background-color: rgb(255, 204, 0);
    text-align: center;
    border-radius: 8px;
    padding-top: 16px;
}

.station_box_open {
    height: 54px;
    background-color: rgba(255, 72, 0, 0.874);
    text-align: center;
    border-radius: 8px;
    padding-top: 16px;
}

.station_box_offline {
    height: 54px;
    background-color: rgba(76, 76, 76, 0.243);
    text-align: center;
    border-radius: 8px;
    padding-top: 16px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

th,
td {
    text-align: left;
    padding: 8px;
}

.col-sub {
    padding-top: 5px;
    width: auto;
    text-align: start;
}

.grid-container-iner {
    display: grid;
    gap: 1em;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
}

.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    height: 100px;
    border-radius: 8px;
    font-size: 18px;
    text-align: center;
}

.col-exp {
    padding-top: 15px;
    padding-bottom: 15px;
    width: auto;
    text-align: center;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    border-top: 0;
    border-color: rgba(128, 128, 128, 0.518);
    border-style: solid;
}
</style>
<template>
    <div class="container">
        <div class="row gx-5 gy-2"
            style="height: 54px; text-align: start; padding-top: 20px; font-size: 24px; margin-bottom: 30px;">
            <div class="col-md-12 col-lg-6" style="padding-left: 12px !important;">StationBox :: {{ code }}</div>
            <div class="col-md-12 col-lg-6" style="padding-left: 12px !important;">
            </div>
        </div>
        <div class="row" style="overflow-x:auto;">
            <table>
                <tr>
                    <th v-for="index in 10" :key="index" >
                        <div class="col" v-for="(item, _index) in sub01" v-bind:key="_index">
                            <div v-if="_index == 'BOX' + index && !item.user && sub01.status == 'online' && item.open != true" class="col station_box" v-on:click="select($event, index, 1,index)">#{{ index }}</div>
                            <div v-if="_index == 'BOX' + index && item.user && sub01.status == 'online' && item.open != true" class="col station_box_use" v-on:click="select($event, index, 1,index)">#{{ index }}</div>
                            <div v-if="_index == 'BOX' + index && sub01.status == 'online' && item.open == true" class="col station_box_open" v-on:click="select($event, index, 1,index)">#{{ index }}</div>
                            <div v-if="_index == 'BOX' + (index) && sub01.status != 'online'" class="col station_box_offline">#{{ index }}</div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th v-for="index in 5" :key="index">
                        <div class="col" v-for="(item, _index) in sub01" v-bind:key="_index">
                            <div v-if="_index == 'BOX' + (index + 10) && !item.user && sub01.status == 'online' && item.open != true" class="col station_box" v-on:click="select($event, index + 10, 1,index + 10)">#{{ index + 10 }}</div>
                            <div v-if="_index == 'BOX' + (index + 10) && item.user && sub01.status == 'online' && item.open != true" class="col station_box_use" v-on:click="select($event, index + 10, 1,index + 10)">#{{ index + 10 }}</div>
                            <div v-if="_index == 'BOX' + (index + 10) && sub01.status == 'online' && item.open == true" class="col station_box_open" v-on:click="select($event, index + 10, 1,index + 10)">#{{ index + 10 }}</div>
                            <div v-if="_index == 'BOX' + (index + 10) && sub01.status != 'online'" class="col station_box_offline">#{{ index + 10 }}</div>
                        </div>
                    </th>
                    <th v-for="index in 5" :key="index">
                        <div class="col" v-for="(item, _index) in sub02" v-bind:key="_index">
                            <div v-if="_index == 'BOX' + (index) && !item.user && sub02.status == 'online' && item.open != true" class="col station_box" v-on:click="select($event, index, 2,index + 15)">#{{ index + 15 }}</div>
                            <div v-if="_index == 'BOX' + (index) && item.user && sub02.status == 'online' && item.open != true" class="col station_box_use" v-on:click="select($event, index, 2,index + 15)">#{{ index + 15 }}</div>
                            <div v-if="_index == 'BOX' + (index) && sub02.status == 'online' && item.open == true" class="col station_box_open" v-on:click="select($event, index, 2,index + 15)">#{{ index + 15 }}</div>
                            <div v-if="_index == 'BOX' + (index) && sub02.status != 'online'" class="col station_box_offline">#{{ index + 15 }}</div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th v-for="index in 10" :key="index">
                        <div class="col" v-for="(item, _index) in sub02" v-bind:key="_index">
                            <div v-if="_index == 'BOX' + (index + 5) && !item.user && sub02.status == 'online' && item.open != true" class="col station_box" v-on:click="select($event, index + 5, 2,index + 20)">#{{ index + 20 }}</div>
                            <div v-if="_index == 'BOX' + (index + 5) && item.user && sub02.status == 'online' && item.open != true" class="col station_box_use" v-on:click="select($event, index + 5, 2,index + 20)">#{{ index + 20 }}</div>
                            <div v-if="_index == 'BOX' + (index + 5) && sub02.status == 'online' && item.open == true" class="col station_box_open" v-on:click="select($event, index + 5, 2,index + 20)">#{{ index + 20 }}</div>
                            <div v-if="_index == 'BOX' + (index + 5) && sub02.status != 'online'" class="col station_box_offline">#{{ index + 20 }}</div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th v-for="index in 10" :key="index">
                        <div class="col" v-for="(item, _index) in sub03" v-bind:key="_index">
                            <div v-if="_index == 'BOX' + (index) && !item.user && sub03.status == 'online' && item.open != true" class="col station_box" v-on:click="select($event, index, 3,index + 30)">#{{ index + 30 }}</div>
                            <div v-if="_index == 'BOX' + (index) && item.user && sub03.status == 'online' && item.open != true" class="col station_box_use" v-on:click="select($event, index, 3,index + 30)">#{{ index + 30 }}</div>
                            <div v-if="_index == 'BOX' + (index) && sub03.status == 'online' && item.open == true" class="col station_box_open" v-on:click="select($event, index, 3,index + 30)">#{{ index + 30 }}</div>
                            <div v-if="_index == 'BOX' + (index) && sub03.status != 'online'" class="col station_box_offline">#{{ index + 30 }}</div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th v-for="index in 5" :key="index">
                        <div class="col" v-for="(item, _index) in sub03" v-bind:key="_index">
                            <div v-if="_index == 'BOX' + (index + 10) && !item.user && sub03.status == 'online' && item.open != true" class="col station_box" v-on:click="select($event, index + 10, 3,index + 40)">#{{ index + 40 }}</div>
                            <div v-if="_index == 'BOX' + (index + 10) && item.user && sub03.status == 'online' && item.open != true" class="col station_box_use" v-on:click="select($event, index + 10, 3,index + 40)">#{{ index + 40 }}</div>
                            <div v-if="_index == 'BOX' + (index + 10) && sub03.status == 'online' && item.open == true" class="col station_box_open" v-on:click="select($event, index + 10, 3,index + 40)">#{{ index + 40 }}</div>
                            <div v-if="_index == 'BOX' + (index + 10) && sub03.status != 'online'" class="col station_box_offline">#{{ index + 40 }}</div>
                        </div>
                    </th>

                    <th v-for="index in 5" :key="index">
                        <div class="col" v-for="(item, _index) in sub04" v-bind:key="_index">
                            <div v-if="_index == 'BOX' + (index) && !item.user && sub04.status == 'online' && item.open != true" class="col station_box" v-on:click="select($event, index, 4,index + 45)">#{{ index + 45 }}</div>
                            <div v-if="_index == 'BOX' + (index) && item.user && sub04.status == 'online' && item.open != true" class="col station_box_use" v-on:click="select($event, index, 4,index + 45)">#{{ index + 45 }}</div>
                            <div v-if="_index == 'BOX' + (index) && sub04.status == 'online' && item.open == true" class="col station_box_open" v-on:click="select($event, index, 4,index + 45)">#{{ index + 45 }}</div>
                            <div v-if="_index == 'BOX' + (index) && sub04.status != 'online'" class="col station_box_offline">#{{ index + 45 }}</div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th v-for="index in 10" :key="index">
                        <div class="col" v-for="(item, _index) in sub04" v-bind:key="_index">
                            <div v-if="_index == 'BOX' + (index + 5) && !item.user && sub04.status == 'online' && item.open != true" class="col station_box"  v-on:click="select($event, index + 5, 4,index + 50)">#{{ index + 50 }}</div>
                            <div v-if="_index == 'BOX' + (index + 5) && item.user && sub04.status == 'online' && item.open != true" class="col station_box_use" v-on:click="select($event, index + 5, 4,index + 50)">#{{ index + 50 }}</div>
                            <div v-if="_index == 'BOX' + (index + 5) && sub04.status == 'online' && item.open == true" class="col station_box_open" v-on:click="select($event, index + 5, 4,index + 50)">#{{ index + 50 }}</div>
                            <div v-if="_index == 'BOX' + (index + 5) && sub04.status != 'online'" class="col station_box_offline">#{{ index + 50 }}</div>
                        </div>
                    </th>
                </tr>
            </table>
        </div>
        <hr/>
        <div class="row" style="overflow-x:auto;">
            <table>
                <tr>
                    <th width="1"><div class="col station_box"> ว่าง</div></th>
                    <th width="1"><div class="col station_box_use"> ไม่ว่าง</div></th>
                    <th width="1"><div class="col station_box_open">มีคำสั่งเปิด</div></th>
                    <th width="1"><div class="col station_box_offline">ออฟไลน์</div></th>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import "firebase/compat/database";
import router from '../router'

export default {
    name: 'Station',
    data() {
        return {
            stations: {},
            sub01: {},
            sub02: {},
            sub03: {},
            sub04: {},
            page_text: "Loading...",
            code: "",
            orderedObj: {},
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || 'Some Default Title';
            }
        },
    },
    mounted() {
        var c = this.$route.params.code.toUpperCase();
        this.code = c;
        firebase.database().ref('StationBox').child('station').child(c).on('value', (snapshot) => {
            this.stations = snapshot.val();
            this.sub01 = snapshot.child('SUB01').val();
            this.sub02 = snapshot.child('SUB02').val();
            this.sub03 = snapshot.child('SUB03').val();
            this.sub04 = snapshot.child('SUB04').val();
            if (!snapshot.hasChild('SUB01')) {
                router.replace("/")
            }
            console.log(this.stations);
        });
    },
    computed: {
        StationBoxData() {
            return this.sortObject(Object.keys(this.stations)[1]);
        },
    },
    methods: {
        sortObject: function (o) {
            return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
        },
        select: function (event, i, ss, t) {
            var c = this.$route.params.code.toUpperCase();
            var s = "SUB0" + ss + "/BOX" + i;
            //alert(c + "/" + s);
            let text = "Confirm open BOX#"+ t +"!";
            if (confirm(text) == true) {
                firebase.database().ref('StationBox').child('station').child(c).child(s).child('open').set(true);
            }            
        },
        getSubdata: function (o, i) {
            return o[i];
        }
    },
}
</script>
<style lang="scss">
/* Clear floats after the columns */
.station_box {
    height: 54px;
    background-color: aliceblue;
    text-align: center;
    border-radius: 8px;
    padding-top: 16px;
}

.station_box_use {
    height: 54px;
    background-color: rgb(255, 204, 0);
    text-align: center;
    border-radius: 8px;
    padding-top: 16px;
}

.station_box_open {
    height: 54px;
    background-color: rgba(255, 72, 0, 0.874);
    text-align: center;
    border-radius: 8px;
    padding-top: 16px;
}

.station_box_offline {
    height: 54px;
    background-color: rgba(76, 76, 76, 0.243);
    text-align: center;
    border-radius: 8px;
    padding-top: 16px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

th,
td {
    text-align: left;
    padding: 8px;
}
</style>
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SigninView from '../views/SigninView.vue'
import StationView from '../views/StationView.vue'
import UserView from '../views/UserView.vue'

const TITLE = 'StationBox - '
const routes = [
  
  {
    path: '/dashboard',
    name: 'home',
    component: HomeView,
    meta: { title: TITLE + 'Dashboard' },
  },
  {
    path: '/signin',
    name: 'signin',
    component: SigninView,
    meta: { title: TITLE + 'SignIn' },
  },
  {
    path: '/user',
    name: 'user',
    component: UserView,
    meta: { title: TITLE + 'User' },
  },
  {
    path: '/station/:code',
    name: 'station',
    component: StationView,
    meta: { title: TITLE + 'Station' },
    props: (route) => ({ code: route.query.code })
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'home',
    component: HomeView,
    meta: { title: TITLE + 'Dashboard' },
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  routes,
})

export default router

<template>
  <Home />
</template>

<script>
// @ is an alias to /src
import Home from '@/components/HomeComp.vue'
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/firestore';
import router from '../router'
export default {
  name: 'HomeView',
  components: {
    Home
  },
  beforeCreate() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        router.replace("/signin")
      }
    });
  },

}
</script>

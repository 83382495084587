import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import firebase from 'firebase/compat/app'
import "firebase/database";


const config = {
    apiKey: 'AIzaSyABzJgvYPJCYf6U6pWzkfLzm_3I_94o2OU',
    authDomain: 'stationbox-d1a53.firebaseapp.com',
    databaseURL: 'https://stationbox-d1a53-default-rtdb.asia-southeast1.firebasedatabase.app/',
    projectId: 'stationbox-d1a53',
    storageBucket: 'stationbox-d1a53.appspot.com',
    messagingSenderId: '1054197565574'
};
firebase.initializeApp(config)
createApp(App).use(BootstrapIconsPlugin)
createApp(App).use(router).mount('#app')

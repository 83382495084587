<template>
  <div class="container">
    <div class="row gx-5 gy-2"
      style="height: 54px; text-align: start; padding-top: 20px; font-size: 24px; margin-bottom: 30px;">
      <div class="col-md-12 col-lg-6" style="padding-left: 12px !important;">StationBox</div>
      <div class="col-md-12 col-lg-6" style="padding-left: 12px !important;">

        <!--<div class="btn-group" style="height: 45px !important; width: 100% !important;">
          <input type="text" class="form-control sinput" style="height: 45px !important;"   placeholder="Station Code" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
          <a href="#" class="btn btn-primary w-50">ค้นหา</a>
        </div>-->

      </div>
    </div>
    <div class="row gx-5 gy-2">
      <div class="col-md-12 col-lg-6" v-for="(item, index) in stations" v-bind:key="index"  v-on:click="select($event, index)">
        <div class="row station" >
          <div class="col col-code">
            {{ index }}
          </div>
          <div class="col" v-for="(item, _index) in stations[index]" v-bind:key="_index">
            <div class="row" style="text-align: start; margin-top: 5px; font-size: 14px;">
              <font color="747474">{{ _index }}</font>
            </div>
            <div class="row" style="text-align: start; padding-left: 5px; font-size: 16px;">
              <div v-if="item['status'] == 'disable'"
                style="background-color:#35353587; border-radius: 10px; margin: 5px; width: 30px; height: 10px;"></div>
              <div v-if="item['status'] == 'offline'"
                style="background-color:#FF0000; box-shadow: 0px 0px 10px 1px #FF0000; border-radius: 10px; margin: 5px; width: 30px; height: 10px;"></div>
              <div v-if="item['status'] == 'online'"
                style="background-color:#3CFF00; box-shadow: 0px 0px 10px 1px #49FF12; border-radius: 10px; margin: 5px; width: 30px; height: 10px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>
<script>
import firebase from 'firebase/compat/app';
import "firebase/compat/database";
import router from '../router'

export default {
  name: 'Home',
  props: {
    msg: String
  },
  data() {
    return {
      stations: {},
      object: {
        key1: 'value1',
        key2: 'value2',
        key3: 'value3',
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Some Default Title';
      }
    },
  },
  mounted() {
    firebase.database().ref('StationBox').child('station').on('value', (snapshot) => {
      /*snapshot.forEach(function (childSnapshot) {
        this.station = childSnapshot.key;
      })*/
      this.stations = snapshot.val();
      console.log(this.stations);
    })
  },
  computed: {
    StationBoxData() {
      return Object.keys(this.stations)[1];
    }
  },
  methods: {
        select: function(event, i) {
          router.replace("/station/"+i)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
/* Clear floats after the columns */


.station {
  clear: both;
  background-color: rgba(255, 255, 255, 0.8);
  height: fit-content;
  border-radius: 8px;
}

textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: none !important;
}

.sinput{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  appearance: none !important;
}

.col-code {
  padding-top: 15px;
  padding-bottom: 15px;
  width: auto;
  text-align: start;
  border-right: 1px;
  border-bottom: 0;
  border-left: 0;
  border-top: 0;
  border-color: rgba(128, 128, 128, 0.518);
  border-style: solid;
}

.col-sub {
  padding-top: 5px;
  width: auto;
  text-align: start;
}

.grid-container-iner {
  display: grid;
  gap: 1em;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}



.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  height: 100px;
  border-radius: 8px;
  font-size: 18px;
  text-align: center;
}
</style>

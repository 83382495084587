<template>
  <div>
    <SignIn />
  </div>
</template>

<script>
import SignIn from '@/components/SigninComp.vue'
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/firestore';
import router from '../router'
export default {
  name: 'SigninView',
  components: {
    SignIn
  },
  beforeCreate() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        router.replace("/")
      }
    });
  }
}
</script>
  <style>

  </style>
<template>
    <UserComp />
</template>
  
  <script>
  // @ is an alias to /src
  import UserComp from '@/components/UserComp.vue'
  import firebase from 'firebase/compat/app';
  import 'firebase/auth';
  import 'firebase/firestore';
  import router from '../router'
  export default {
      name: 'UserView',
      components: {
          UserComp
      },
      beforeCreate() {
          firebase.auth().onAuthStateChanged((user) => {
              if (!user) {
                  router.replace("/signin")
              }
          });
      },
  
  }
  </script>
  